.btn {
  border-radius: 50px;
  padding: .375rem .75rem !important; }
  .btn-outline-secondary {
    border-color: #6C757D; }
    .btn-outline-secondary:hover, .btn-outline-secondary:focus {
      background: #5a6268;
      border-color: #5a6268;
      text-decoration: none; }
  .btn-secondary {
    font-weight: 700;
    font-size: 13px; }
    .btn-secondary:hover, .btn-secondary:focus {
      background: #5a6268;
      border-color: #5a6268;
      text-decoration: none; }

html {
  scroll-behavior: smooth; }

body.sticky .navbar {
  background: #1A1A1A; }

header .navbar {
  background: #1A1A1A; }
  header .navbar-brand {
    line-height: 0; }
    header .navbar-brand img {
      height: 25px; }
      @media screen and (max-width: 575px) {
        header .navbar-brand img {
          height: 20px; } }
  @media screen and (max-width: 991px) {
    header .navbar-nav {
      margin-top: 10px; } }
  header .navbar .language-list {
    display: flex; }
    header .navbar .language-list .language-item {
      margin-left: 10px;
      margin-top: 2px;
      margin-bottom: 1px;
      width: 25px;
      opacity: 0.3; }
      header .navbar .language-list .language-item.active {
        opacity: 1; }
  header .navbar .nav-item .nav-link {
    text-transform: uppercase;
    color: white;
    transition: .15s;
    margin-left: 10px;
    border-radius: 50px;
    padding: .375rem .75rem !important;
    font-size: 13px;
    font-weight: 600;
    text-align: center; }
    @media screen and (max-width: 991px) {
      header .navbar .nav-item .nav-link {
        margin-left: 0px; } }
    header .navbar .nav-item .nav-link:hover, header .navbar .nav-item .nav-link:focus {
      background: #5a6268;
      color: white; }
  @media screen and (max-width: 991px) {
    header .navbar .nav-item.buyAppBtn {
      display: none; } }
  header .navbar .nav-item .dropdown-toggle {
    font-size: 13px;
    font-weight: 600; }
  header .navbar .nav-item .dropdown-item {
    display: flex;
    align-items: center; }
    header .navbar .nav-item .dropdown-item img {
      height: 16px;
      margin-right: 4px; }
  header .navbar .nav-item .btn {
    margin-left: 20px; }
  @media screen and (max-width: 991px) {
    header .navbar .nav-item {
      margin: 0px 0px 7px 0px !important; }
      header .navbar .nav-item .btn {
        margin-left: 0px; } }
  header .navbar-toggler {
    border: none;
    background: transparent !important;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 4px; }
    header .navbar-toggler .icon-bar {
      width: 22px;
      height: 2px;
      background-color: white;
      display: block;
      transition: all 0.2s;
      margin-top: 4px; }
    header .navbar-toggler .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%; }
    header .navbar-toggler .middle-bar {
      opacity: 0; }
    header .navbar-toggler .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%; }
    header .navbar-toggler.collapsed .top-bar {
      transform: rotate(0); }
    header .navbar-toggler.collapsed .middle-bar {
      opacity: 1; }
    header .navbar-toggler.collapsed .bottom-bar {
      transform: rotate(0); }
